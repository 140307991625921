import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import SnowLayout from '../components/SnowLayout';
import SnowLinks from '../components/SnowLinks';
import Seo from '../components/Seo';
import Cta from '../components/Cta';

import '../styles/global.css';

export default function Snowplowing({ data }) {
   return (
      <SnowLayout>
         <Seo title='Snow Plowing' />
         <SnowPlowingHeader />
         <SnowLinks />
         <section className='text-gray-600 body-font'>
            <div className='px-5 py-8 md:py-24 mx-auto flex flex-wrap'>
               <div className='max-w-7xl mx-auto'>
                  <div className='flex flex-wrap w-full h-64 md:h-96 relative mb-4'>
                     <StaticImage
                        alt='Hunter Environmental front end loader plowing snow'
                        src='../images/Hunter-Environmental-Snow-Plowing-Feature-1.jpg'
                        className='w-full object-cover h-full object-top block absolute inset-0'
                     />
                  </div>
                  <div className='flex flex-wrap -mx-2'>
                     <div className='px-2 w-1/2'>
                        <div className='flex flex-wrap w-full relative h-32 md:h-64'>
                           <StaticImage
                              alt='Hunter Environmental plow truck plowing snow'
                              src='../images/Hunter-Environmental-Snow-Plowing-Feature-2.jpg'
                              className='w-full object-cover h-full object-center block  absolute inset-0'
                           />
                        </div>
                     </div>
                     <div className='px-2 w-1/2'>
                        <div className='flex flex-wrap w-full relative h-32 md:h-64'>
                           <StaticImage
                              alt='Hunter Environmental front end loader plowing snow'
                              src='../images/Hunter-Environmental-Snow-Plowing-Feature-3.jpg'
                              className='w-full object-cover h-full object-bottom block absolute inset-0'
                           />
                        </div>
                     </div>
                  </div>

                  <div className='mx-auto mt-8 text-base  max-w-prose'>
                     <div>
                        <h2 className='text-base text-snow text-center font-semibold tracking-wide uppercase'>Winter Storm Services</h2>
                        <h3 className='mt-2 text-3xl leading-8 text-center font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
                           {data.snowservice.name}
                        </h3>
                     </div>
                     <div className='text-base prose max-w-prose mx-auto lg:max-w-none mt-8'>
                        <p className='text-lg text-gray-500'>{data.snowservice.desc}</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <Cta bgColor='bg-snow' bgHover='hover:bg-snow-dark' textColor='text-snow' />
      </SnowLayout>
   );
}

const SnowPlowingHeader = () => {
   return (
      <div className='grid'>
         <StaticImage
            style={{ gridArea: '1/1' }}
            layout='fullWidth'
            alt='Hunter Environmental Front End Loader moving snow'
            src='../images/Hunter-Environmental-Snow-Plowing-Header.jpg'
            formats={['auto', 'webp', 'avif']}
            className='h-128'
         />
         <div aria-hidden='true' style={{ gridArea: '1/1' }} className='bg-gradient-to-b from-black opacity-80 relative inset-0 z-10' />
         <div className='grid relative items-center w-full z-20' style={{ gridArea: '1/1' }}>
            <div className='w-full h-fit'>
               <div className='flex flex-col justify-center items-center md:items-start max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:px-0'>
                  <div className='text-4xl lg:text-6xl text-center md:text-left lg:pl-2 my-6 md:border-l-8 md:border-snow font-bold text-white'>
                     <h1 className='mb-4 text-center md:text-left'>Snow</h1>
                     <h1>Plowing</h1>
                  </div>
                  <AnchorLink
                     to='/dirt#contact'
                     style={{ fontFamily: 'Muli' }}
                     className='w-max md:ml-4 shadow-lg mt-8 bg-snow hover:bg-snow-light  text-xs md:text-base rounded-md text-white px-12 py-2'
                  >
                     Contact Us
                  </AnchorLink>
               </div>
            </div>
         </div>
      </div>
   );
};

export const pageQuery = graphql`
   query {
      snowservice(name: { in: "Snow Plowing" }) {
         id
         name
         desc
         brief
      }
   }
`;
